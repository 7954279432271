import React, { useEffect, useState } from "react";
import JL_Logo from "./../../assets/logo/jl-logo.png";
import { effectIcons, speciesIcons } from "../../assets/iconFile";
import letter1Img from "./../../assets/img/Joyleaf_Species_Sativa.jpg";


export const ListLabelPrint = React.forwardRef(({ productList }, ref) => {

    const [products, setProducts] = useState([])

    useEffect(() => {
        let items = [];
        for (let i = 0; i < productList.length; i += 11) {
            items.push(productList.slice(i, i + 11));
        }
        setProducts(items)
    }, [productList])

    const getLabelSpeciesImage = (item) => {
        let speciesKeys = Object.keys(speciesIcons);
        if (item && item.productStrainPrevalence) {
            if (item.productStrainPrevalence.toUpperCase().search('DOMINANT') >= 0) {
                if (item.productStrainPrevalence.toLowerCase().search('sativa') >= 0) {
                    let indx = speciesKeys.findIndex(e => e == 'hybridsativa');
                    if (indx >= 0) {
                        return speciesIcons[speciesKeys[indx]]
                    }
                }
                else if (item.productStrainPrevalence.toLowerCase().search('indica') >= 0) {
                    let indx = speciesKeys.findIndex(e => e == 'hybridindica');
                    if (indx >= 0) {
                        return speciesIcons[speciesKeys[indx]]
                    }
                }
            }
            let productStrain = item.productStrainPrevalence.split(" ").join("").toLowerCase()
            let indx = speciesKeys.findIndex(e => e == productStrain);
            if (indx >= 0) {
                return speciesIcons[speciesKeys[indx]]
            }

        }
        return letter1Img
    }

    const getLabelEffectImage = (item) => {
        let effectKeys = Object.keys(effectIcons);
        if (item && item.productEffectName) {
            let indx = effectKeys.findIndex(e => e == item.productEffectName.toLowerCase());
            if (indx >= 0) {
                return effectIcons[effectKeys[indx]]
            }
        }
        return ''
    }
    const getUOMShort = (unit) => {
        if (unit && unit.toLowerCase() === "milligram") {
            return 'MG'
        }
        else if (unit) {
            return unit.charAt(0)
        }
        else return ''
    }

    const getUnitFromVariant = (item, indx) => {
        if (item) {
            const unitItems = replaceText(item);
            const unitArray = unitItems.split('|');
            const actualUnit = unitArray[indx].replace(/[^0-9/.?]/g, "");
            return roundToTwo(actualUnit);
        }
    }

    const replaceText = (item) => {
        if (item && item.productName && item.productGroupName) {
            return (item.productName.replace(item.productGroupName, '')).trim()
        }
        else {
            return ''
        }
    }

    function roundToTwo(num) {
        return +(Math.round(num + "e+2") + "e-2");
    }

    return (
        <div className="card bl-border-purple">
            <div className="card-header bl-border-bottom-purple bl-text-purple textSize2 px-3 py-2">Product Card Preview</div>
            <div ref={ref} className="card-body">
                {
                    products && products.map((item, i) => {
                        return (
                            <div key={`list-page-${i}`} className="p-3 bl-text-purple">
                                <div className="d-flex bl-border-bottom-purple">
                                    <h5 className="text-uppercase bl-ozik-medium-20">{item[0].productCategory} / {item[0].productSubCategory}</h5>
                                </div>
                                <div className="bl-border-bottom-purple mt-2">
                                    <h1 className="text-uppercase bl-product-name my-4">{item[0].productBrand} / {getUnitFromVariant(item[0], 0)}{getUOMShort(item[0].uom)}</h1>
                                </div>
                                <table className="table table-borderless">
                                    <thead>
                                        <tr className="bl-border-bottom-purple border-left-0 border-right-0 border-top-0">
                                            <th scope="col"><h4 className="text-uppercase bl-ozik-bold-14pt bl-text-purple mb-0">STRAIN / FLAVOR</h4></th>
                                            <th scope="col"><h4 className="text-uppercase bl-ozik-bold-14pt bl-text-purple mb-0">SPECIES</h4></th>
                                            <th scope="col"><h4 className="text-uppercase bl-ozik-bold-14pt bl-text-purple mb-0">THC</h4></th>
                                            <th scope="col"><h4 className="text-uppercase bl-ozik-bold-14pt bl-text-purple mb-0">PRICE</h4></th>
                                            <th scope="col"><h4 className="text-uppercase bl-ozik-bold-14pt bl-text-purple mb-0 text-center">EFFECT</h4></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            item && item.map((p, j) => {
                                                return (
                                                    <tr key={`list-print-${i}${j}`}>
                                                        <td className="text-uppercase bl-barlow-bold-18pt bl-text-purple">{p.productStrain}</td>
                                                        <td className="text-uppercase bl-product-strain bl-text-purple">
                                                            {
                                                                p.productStrainPrevalence && p.productStrainPrevalence.toLowerCase() !== 'n/s' ? (
                                                                    <div className="d-flex align-items-center">
                                                                        <img src={getLabelSpeciesImage(p)} alt="" height="26px" className="mr-2" />
                                                                        <h5 className="mb-0 bl-product-strain">{p.productStrainPrevalence}</h5>
                                                                    </div>
                                                                ) : null
                                                            }

                                                        </td>
                                                        <td className="text-uppercase bl-barlow-bold-16pt bl-text-purple">{p.thc ? Math.round(p.thc) : 0}%</td>
                                                        <td className="text-uppercase bl-barlow-bold-16pt bl-text-purple">${p.productPrice}</td>
                                                        <td className="text-uppercase bl-barlow-bold-18pt bl-text-purple text-center">
                                                            {
                                                                p.productEffectName ? <img src={getLabelEffectImage(p)} alt="" height="26px" /> : null
                                                            }

                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                                <div className="d-flex justify-content-between align-items-baseline mt-5 mb-3">
                                    <h5 className="text-uppercase mb-0 bl-product-id"></h5>
                                    <img src={JL_Logo} alt="JL-logo" className="img-fluid bl-jf-logo" />
                                </div>
                                <div className="bl-border-purple border-3 mb-2 bl-border-radius-5px"></div>
                                <div className="pagebreak"></div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}
)