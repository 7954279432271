import React, { useContext, useEffect, useRef, useState } from "react";
import homeLabel from "./../../utils/property-file/componentOverview.json";
import bannerIcon from "./../../assets/logo/JL_Logo.webp";
import { ProductDetailModal } from "./productDetailModal";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { CarouselComponent } from "../Common/carousel";
import { useReactToPrint } from 'react-to-print';
import { PrintProductLabel } from "./printProductLabel";
import { getCabinetsService, getCategoryByCabinetsService, getProductCountService, getProductImageService, getProductListService, getSubCategoryAndStrianByCabinetCategoryService, modifyProductCabinetService, storeProductCustomCBDTHCService } from "../../services/productService";
import { ListLabelPrint } from "./listLabelPrintLayout";
import imgPreview from "./../../assets/img/no-img.png";
import listViewLoader from "./../../assets/img/loading-animation.gif";
import no_products_found from "./../../assets/img/no_products_found.png";
import UnknownErrorComponent from "../Error/unknownError";
import NotifyService from "../../services/notify.service";
import moment from "moment";
import LayoutContext from "../../services/context/layout/context";
import { toast } from "react-toastify";


export const ProductsComponent = () => {

    const { setBreadcrumbContext } = useContext(LayoutContext);
    const { globalSearch } = useContext(LayoutContext);
    const { cabinetSelection } = useContext(LayoutContext);

    const contentRef = useRef(null);
    const isFetching = useRef(false);
    const debounceTimeout = useRef(null);
    const [labels, setLabels] = useState({
        WELCOME: "", WELCOME_DESC: "", WELCOME_ICON: "", GUIDE: "", CONTETNT_TITLE: "", TIPS: ""
    });
    const [isLoaded, setIsLoaded] = useState(false)
    const [productList, setProductList] = useState([])
    const [selectedProduct, setSelectedProduct] = useState('')
    const [productCabinet, setProductCabinet] = useState('')
    const [viewType, setViewType] = useState('LIST')
    const [printedProducts, setPrintedProducts] = useState([])
    const [limit] = useState(50);
    const [page, setPage] = useState(1)
    const [fetchedProductCount, setFetchedProductCount] = useState(0)
    const [cabinetIdList, setcabinetIdList] = useState([])
    const [categoryList, setCategoryList] = useState([])
    const [subcategoryList, setSubCategoryList] = useState([])
    const [strainList, setStrainList] = useState([])
    const [filterObj, setFilterObj] = useState({
        cabinetIds: [], category: '', subCategory: '', strain: '', search: ''
    })
    const [selectedCategory, setSelectedCategory] = useState('')
    const [selectedSubCategory, setSelectedSubCategory] = useState('')
    const [selectedStrain, setSelectedStrain] = useState('')
    const [selectedStock, setSelectedStock] = useState('')
    const [pickedCategory, setPickedCategory] = useState('')
    const [pickedSubCategory, setPickedSubCategory] = useState('')
    const [pickedStrain, setPickedStrain] = useState('')
    const [pickedStock, setPickedStock] = useState('')
    const [search, setSearch] = useState('')
    const [gridSort, setGridSort] = useState([
        { name: "ProductId", val: "productID", isSorted: true },
        { name: "Product Name", val: "productGroupName", isSorted: false },
        { name: "Brand", val: "productBrand", isSorted: false },
        { name: "Category", val: "productCategory", isSorted: false },
        { name: "Sub-Category", val: "productSubCategory", isSorted: false },
        { name: "Strain", val: "productStrain", isSorted: false },
        { name: "Prevalence", val: "productStrainPrevalence", isSorted: false },
        { name: "Size", val: "packOfSize", isSorted: false },
        { name: "Cabinet", val: "cabinetName", isSorted: false },
        { name: "Updated On", val: "updatedOn", isSorted: false },
        { name: "Inventory", val: "inventoryCount", isSorted: false }
    ])
    const [filterList, setFilterList] = useState([])
    const [sortType, setSortType] = useState('DESC')
    const [productSortBy, setProductSortBy] = useState('productId')
    const [productSortByName, setProductSortByName] = useState('ProductId')
    const [printType, setPrintType] = useState('CARD')
    const [isMultiBrandForListView, setisMultiBrandForListView] = useState(false)
    const [unassignedProduct, setUnassignedProduct] = useState(false)
    const [showAssignedButton, setShowAssignedButton] = useState(false)
    const [showUnAssignedButton, setShowUnAssignedButton] = useState(false)
    const [selectedProductList, setSelectedProductList] = useState([])
    const [cabinets, setCabinets] = useState([])
    const [selectedCabinet, setSelectedCabinet] = useState("")
    const [isLoading, setIsLoading] = useState(false);
    const [triggerProduct, setTriggerProduct] = useState(false);
    const [totalProductCount, setTotalProductCount] = useState(0)
    const [isErrorOccured, setIsErrorOccured] = useState(false)
    let sortBy = 'productId';
    let sortOrder = "DESC";

    const stickyContent = () => {
        let stickyContainer = document.querySelector('.sticky-container');
        let sticky = document.getElementById('sticktitle');
        let stickyOffset = stickyContainer?.offsetTop
        if (window.scrollY >= stickyOffset) {
            sticky.classList.add('bl-sticky-title');
        } else {
            sticky.classList.remove('bl-sticky-title');
        }
    }

    useEffect(() => {
        setBreadcrumbContext([{ slug: '/products', menuName: 'Product', isActive: true, hasSubMenu: true }])
        setIsErrorOccured(false)
    }, [])

    useEffect(() => {
        let items = homeLabel.find(l => l.FEATURE === "PRODUCTS").items;
        setLabels(items)
    }, [homeLabel])

    useEffect(() => {
        const contentElement = contentRef.current;
        if (contentElement) {
            window.addEventListener('scroll', scroll);
        }
        return () => {
            window.removeEventListener('scroll', scroll);
            if (debounceTimeout.current) {
                clearTimeout(debounceTimeout.current);
            }
        };
    }, [fetchedProductCount, productList, page, isLoading])

    useEffect(() => {
        getProductList({ filterItem: null })
    }, [page])

    const scroll = () => {
        stickyContent();
        if (isHalfwayDown() && !isLoading && !isFetching.current) {
            if (fetchedProductCount === limit) {
                setIsLoading(true);
                setPage(prevPage => prevPage + 1);
                debounceTimeout.current = setTimeout(() => {
                    getProductList({ filterItem: null })
                }, 200);

            }

        }
    }

    const isHalfwayDown = () => {
        const content = contentRef.current;
        return (
            content &&
            window.innerHeight + window.scrollY >= content.offsetTop + content.clientHeight
        );

    };

    const getProductList = async ({ filterItem, pSortBy, pSortOrder, nextPage }) => {
        try {
            isFetching.current = true;
            let filterProd = filterItem ? filterItem : {
                cabinetIds: cabinetIdList, category: pickedCategory, subCategory: pickedSubCategory,
                strain: pickedStrain, search: search, unassignedProduct: unassignedProduct, stock: pickedStock
            }
            let obj = {
                limit: limit,
                page: nextPage ? nextPage : page,
                sortBy: pSortBy ? pSortBy : productSortBy ? productSortBy : sortBy,
                sortType: pSortOrder ? pSortOrder : sortType ? sortType : sortOrder
            }
            setFetchedProductCount(0)
            setIsLoaded(false)
            if (obj.page === 1) {
                productCount(obj, filterProd);
            }
            const resp = await getProductListService(obj, filterProd);
            if (resp.success) {
                let productsCount = resp.data.length;
                let list = await resp.data.map(prod => {
                    prod.customCbd = prod?.customCbd || "";
                    prod.customCbdSelected = prod?.customCbdSelected || false;
                    prod.customThc = prod?.customThc || "";
                    prod.customThcSelected = prod?.customThcSelected || false;
                    prod.shortDescription = prod?.shortDescription || "";
                    prod.isSelected = false;
                    return prod;
                });
                setFetchedProductCount(productsCount)
                let productItems = [...productList, ...list]
                setTimeout(() => {
                    setIsLoaded(true)
                    if (obj.page === 1) {
                        setProductList(resp.data)
                    }
                    else setProductList(productItems)

                }, 50)
            }
            else {
                setIsLoaded(true)
            }
        }
        catch (e) {
            if (e && e.status === 500) {
                setIsErrorOccured(true)
            }
        }
        finally {
            isFetching.current = false;
            setIsLoading(false);
        }

    }

    const productCount = async (obj, filterProd) => {
        const resp = await getProductCountService(obj, filterProd);
        if (resp && resp.success) {
            setTotalProductCount(resp.data.productCount)
        }
    }

    const getProductImage = async (productId) => {
        let resp = await getProductImageService(productId)
        if (resp.success) {
            return resp.data
        }
        return null
    }

    useEffect(() => {
        setPage(1);
        setProductList([]);
        setFetchedProductCount(0)
        setTimeout(() => {
            getProductList({ filterItem: null })
        }, 200)
    }, [cabinetIdList])

    useEffect(() => {
        if (cabinetSelection) {
            setFilterList(cabinetSelection.cabinets)
            setUnassignedProduct(cabinetSelection.unassignedProduct)
            let cabinetIds = cabinetSelection.cabinets && cabinetSelection.cabinets.map(f => { return f.cabinetId })
            let filetrsObj = {
                cabinetIds: cabinetIds, category: '', subCategory: '', strain: '', search: search, unassignedProduct: cabinetSelection.unassignedProduct
            }
            setcabinetIdList(cabinetIds);
            setCategoryList([]);
            setSubCategoryList([]);
            setStrainList([]);
            setFilterObj(filetrsObj);
            getCategoryByCabinets({ cabinetIds, unassigned: cabinetSelection.unassignedProduct });
            setSelectedCategory('');
            setSelectedSubCategory('');
            setSelectedStrain('');
            setSelectedStock('')
            setPickedCategory('')
            setPickedSubCategory('')
            setPickedStrain('')
            setPickedStock('')
            setPage(1);
            setProductList([]);
            setFetchedProductCount(0)
        }
    }, [cabinetSelection])

    useEffect(() => {
        setPage(1);
        setProductList([]);
        setFetchedProductCount(0)
        setTimeout(() => {
            getProductList({ filterItem: null, nextPage: 1 })
        }, 200)
    }, [search])

    useEffect(() => {
        if (search === globalSearch) {
            return
        }
        let seachProd = globalSearch ? globalSearch : '';
        setSearch(seachProd)
        let filetrsObj = {
            cabinetIds: cabinetIdList, category: selectedCategory, subCategory: selectedSubCategory,
            strain: selectedStrain, search: seachProd, unassignedProduct: unassignedProduct, stock: selectedStock
        };
        setFilterObj(filetrsObj)
        setPage(1);
        setProductList([]);
        setFetchedProductCount(0)
    }, [globalSearch])

    const getCategoryByCabinets = async ({ cabinetIds, unassigned }) => {
        let obj = {
            cabinet: cabinetIds,
            unassignedProduct: unassigned
        }
        let resp = await getCategoryByCabinetsService(obj);
        if (resp.success) {
            setCategoryList(resp.data)
            let filetrsObj = {
                cabinetIds: cabinetIdList, category: '', subCategory: '', strain: '', search: search
            }
            setSelectedSubCategory('')
            setSelectedStrain('')
            setPickedSubCategory('')
            setPickedStrain('')
            setSubCategoryList([])
            setStrainList([])
            setFilterObj(filetrsObj)
        }
    }

    const getSubCategoryAndStrianByCabinetCategory = async (e) => {
        let category = e.target.value;
        e.preventDefault();
        setSelectedCategory(category)
        let obj = {
            cabinet: cabinetIdList,
            unassignedProduct: unassignedProduct
        }
        let resp = await getSubCategoryAndStrianByCabinetCategoryService(obj, category);
        if (resp.success) {
            setSubCategoryList(resp.data.subCategoryList)
            setStrainList(resp.data.strainList)
            let filetrsObj = {
                cabinetIds: cabinetIdList, category: category, subCategory: '', strain: '', search: search
            }
            setFilterObj(filetrsObj)
        }
    }

    const applyFilters = (e) => {
        e.preventDefault();
        setProductList([]);
        setFetchedProductCount(0)
        setPickedCategory(selectedCategory)
        setPickedSubCategory(selectedSubCategory)
        setPickedStrain(selectedStrain)
        setPickedStock(selectedStock)
        setPage(1);
        setTimeout(() => {
            let filetrsObj = {
                cabinetIds: cabinetIdList, category: selectedCategory, subCategory: selectedSubCategory,
                strain: selectedStrain, search: search, unassignedProduct: unassignedProduct, stock: selectedStock
            }
            setFilterObj(filetrsObj)
            getProductList({ filterItem: filetrsObj, nextPage: 1 })
        }, 200)
    }

    const clearFilters = (e) => {
        e.preventDefault();
        let filetrsObj = {
            cabinetIds: cabinetIdList, category: '', subCategory: '', strain: '', search: search, unassignedProduct: unassignedProduct
        }
        setSelectedCategory('')
        setSelectedSubCategory('')
        setSelectedStrain('')
        setSelectedStock('')
        setPickedCategory('')
        setPickedSubCategory('')
        setPickedStrain('')
        setPickedStock('')
        setPage(1)
        setProductList([])
        setFetchedProductCount(0)
        setFilterObj(filetrsObj)
        setTimeout(() => {
            getProductList({ filterItem: filetrsObj, nextPage: 1 })
        }, 200)
    }

    const changeViewType = (type) => {
        if (type !== viewType) {
            window.scrollTo(0, 0)
            setViewType(type)
        }
    }

    const selectProductToPrint = (productId) => {
        let prodList = [];
        prodList = productList && productList.map(prd => {
            if (prd.productID == productId) {
                prd.isSelected = !prd.isSelected;
                if (prd.isSelected) {
                    prd.labelCount = 1;
                }
            }
            return prd;
        })
        setProductList(prodList)
    }

    useEffect(() => {
        const selectedProd = productList.filter(p => p.isSelected);
        if (selectedProd.length) {
            let cabinetAssigned = productList.filter(p => p.cabinetId && p.isSelected);
            if (cabinetAssigned.length) {
                setShowUnAssignedButton(true);
                setShowAssignedButton(false)
            }
            else {
                setShowUnAssignedButton(false);
                setShowAssignedButton(true)
            }
        }
        else {
            setShowAssignedButton(false)
            setShowUnAssignedButton(false)
        }
    }, [productList])

    const productLabelCount = (productId, count) => {
        let prodList = productList && productList.map(prd => {
            if (prd.productID == productId) {
                prd.labelCount = count;
            }
            return prd;
        })
        setProductList(prodList)
    }

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });
    const componentRefList = useRef();
    const handlePrintList = useReactToPrint({
        content: () => componentRefList.current,
    });

    const printLabel = () => {
        setisMultiBrandForListView(false)
        let items = [];
        let canPrint = true;
        productList && productList.map((prd) => {
            if (prd.isSelected) {
                if (prd.productCategory === 'Accessories') {
                    canPrint = false;
                    return
                }
                for (let i = 1; i <= prd.labelCount; i++) {
                    items.push(prd)
                }
            }
        })
        if (canPrint) {
            if (items.length) {
                setPrintedProducts(items)
                setTimeout(() => {
                    if (printType === 'CARD') {
                        handlePrint()
                        setTimeout(() => {
                            window.$('#printalertmodal').modal('hide')
                        }, 1000)
                    }
                    else {
                        let sameBrandArray = isFieldSame(items, 'productBrand');
                        let sameSizeArray = isFieldSame(items, 'packOfSize');
                        if (sameBrandArray && sameSizeArray) {
                            handlePrintList()
                            setTimeout(() => {
                                window.$('#printalertmodal').modal('hide')
                            }, 1000)
                        }
                        else {
                            setisMultiBrandForListView(true)
                        }
                    }
                }, 50)
            }
            else {
                NotifyService.warning('Please select at-least one product to print the label!')
            }
        }
        else {
            NotifyService.info("Product Cards for Accessories not available!")
        }
    }

    function isFieldSame(array, fieldName) {
        if (array.length === 0) {
            // Return true for an empty array since there are no differing values to compare.
            return true;
        }

        const firstValue = array[0][fieldName];

        for (let i = 1; i < array.length; i++) {
            if (array[i][fieldName] !== firstValue) {
                return false;
            }
        }

        return true;
    }

    const sortProducts = (e, sortIndx) => {
        if (e) {
            e.preventDefault();
        }
        if (gridSort[sortIndx].isSorted) {
            let _sortType = sortType === "DESC" ? "ASC" : "DESC";
            setSortType(_sortType)
            sortOrder = _sortType;
        }
        let sortList = gridSort && gridSort.map(g => {
            g.isSorted = false;
            return g;
        });
        sortList[sortIndx].isSorted = true;
        sortBy = sortList[sortIndx].val;
        setProductSortBy(sortBy)
        setProductSortByName(sortList[sortIndx].name)
        setGridSort(sortList)
        setPage(1)
        setProductList([])
        setFetchedProductCount(0)
        setTimeout(() => {
            getProductList({ filterItem: null, pSortBy: sortBy, pSortOrder: sortOrder, nextPage: 1 })
        }, 200)
    }

    const getSelectedProducts = (e) => {
        let element = document.getElementById('cabinetproductassignunassign');
        element.classList.remove('was-validated')
        setSelectedCabinet('')
        e.preventDefault();
        let items = productList.filter((prd) => prd.isSelected)
        setSelectedProductList(items)
        getCabinets();
    }

    const getCabinets = async () => {
        let resp = await getCabinetsService();
        if (resp.success) {
            if (showUnAssignedButton) {
                let none = { cabinetId: 'REMOVE', cabinetName: "Deallocate Cabinet" }
                setCabinets([...resp.data, none])
            }
            else {
                setCabinets(resp.data)
            }
        }
    }

    const modifyProductCabinet = async (e) => {
        e.preventDefault();
        let products = selectedProductList && selectedProductList.map(p => { return p.productID })
        let element = document.getElementById('cabinetproductassignunassign');
        element.classList.add('was-validated')

        if (selectedCabinet) {
            let obj = {
                cabinetId: selectedCabinet,
                products: products
            }
            let resp = await modifyProductCabinetService(obj)
            if (resp.success) {
                setTriggerProduct(!triggerProduct)
                window.$('#cabinetproductmodal').modal('hide')
                NotifyService.success(resp.message)
                setPage(1);
                setFetchedProductCount(0)
                setProductList([]);
                setTimeout(() => {
                    getProductList({ filterItem: null, nextPage: 1 })
                }, 200)
            }
            else {
                if (resp.message) {
                    NotifyService.error(resp.message)
                }
            }
        }
    }

    const changeProductState = async (product, state, type) => {
        const list = [...productList];
        const updateIndx = list.findIndex(prod => prod.productID === product.productID);
        if(type === 'CBD'){
            list[updateIndx].customCbdSelected = state ? 1 : 0;
        }
        else {
            list[updateIndx].customThcSelected = state ? 1 : 0
        }
        setTimeout(()=>{
            setProductList(list)
        }, 80)

        setSelectedProduct('')
        
        const obj = {
            productId: product.productID,
            customCbd: product.customCbd ? product.customCbd : null,
            customThc: product.customThc ? product.customThc : null,
            customCbdSelected: type === 'CBD' ? state ? 1 : 0 : product.customCbdSelected ? 1 : 0,
            customThcSelected: type === 'THC' ? state ? 1 : 0 : product.customThcSelected ? 1 : 0
        }
        const resp = await storeProductCustomCBDTHCService(obj);
        if(resp && resp.success){
            // toast.success("Product updated successfully!")
        }
    }

    const getUOMShort = (unit) => {
        if (unit && unit.toLowerCase() === "milligram") {
            return 'MG'
        }
        else if (unit && unit.toLowerCase() === "each") {
            return ' EA'
        }
        else if (unit) {
            return unit.charAt(0)
        }
        else return ''
    }

    const arrangeColumnFiled = (item, column) => {
        let content;
        if (item) {
            switch (column) {
                case 'NAME':
                    content = (
                        <div className="d-flex align-items-center">
                            <div className="form-check">
                                <input className="form-check-input bl-border-purple" type="checkbox" onChange={() => selectProductToPrint(item.productID)} value={''} checked={item.isSelected} id="flexCheckDefault" />
                            </div>
                            <div className="pl-2" data-bs-toggle="modal" data-bs-target="#productdetailmodal">
                                <p className="mb-0 text-nowrap"><b>{item.productGroupName}</b></p>
                            </div>
                        </div>
                    )
                    break;
                case 'ACTION':
                    content = (
                        <div className={`${item.isSelected ? 'bl-w-5rem' : 'd-none'}`}>
                            <div className="input-group input-group-sm">
                                <div className="input-group-prepend">
                                    <span className="input-group-text bg-white bl-text-purple bl-border-color-purple cp" onClick={() => { item.labelCount > 1 ? productLabelCount(item.productID, parseInt(item.labelCount) - 1) : productLabelCount(item.productID, 1) }}>
                                        <i className="fa fa-minus" aria-hidden="true"></i>
                                    </span>
                                </div>
                                <input type="text" className="form-control text-center h-auto bg-white bl-text-purple bl-border-color-purple p-0" value={item.labelCount} disabled />
                                <div className="input-group-append">
                                    <span className="input-group-text bg-white bl-text-purple bl-border-color-purple cp" onClick={() => productLabelCount(item.productID, parseInt(item.labelCount) + 1)}>
                                        <i className="fa fa-plus" aria-hidden="true"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                    )
                    break;
                case 'ID':
                    content = (<p className="mb-0" data-bs-toggle="modal" data-bs-target="#productdetailmodal">SKU#{item.productID}</p>)
                    break;
                case 'CABINET':
                    content = (
                        item.cabinetName ? <p className="mb-0" data-bs-toggle="modal" data-bs-target="#productdetailmodal">{item.cabinetName}</p> : <p data-bs-toggle="modal" data-bs-target="#productdetailmodal" className="mb-0 badge bl-chip bg-danger text-white border-0">{'Not Assigned'}</p>
                    )
                    break;
                case 'BRAND':
                    content = (<p className="mb-0" data-bs-toggle="modal" data-bs-target="#productdetailmodal">{item.productBrand}</p>)
                    break;
                case 'CATEGORY':
                    content = (<p className="mb-0" data-bs-toggle="modal" data-bs-target="#productdetailmodal">{item.productCategory}</p>)
                    break;
                case 'SUB-CATEGORY':
                    content = (<p className="mb-0" data-bs-toggle="modal" data-bs-target="#productdetailmodal">{item.productSubCategory}</p>)
                    break;
                case 'STRAIN':
                    content = (<p className="mb-0" data-bs-toggle="modal" data-bs-target="#productdetailmodal">{item.productStrain}</p>)
                    break;
                case 'PREVALENCE':
                    content = (<p className="mb-0" data-bs-toggle="modal" data-bs-target="#productdetailmodal">{item.productStrainPrevalence}</p>)
                    break;
                case 'SIZE':
                    content = (<p className="mb-0" data-bs-toggle="modal" data-bs-target="#productdetailmodal">{getUnitFromVariant(item, 0)}{getUnitFromVariant(item, 0) ? getUOMShort(item.uom) : ''}</p>)
                    break;
                case 'INVENTORY':
                    if (item.inventoryCount && parseFloat(item.inventoryCount) > 0) {
                        content = (<p className={`mb-0 text-success`} data-bs-toggle="modal" data-bs-target="#productdetailmodal"><b>{item.inventoryCount}</b></p>)
                    }
                    else {
                        content = (<p className={`mb-0 text-danger`} data-bs-toggle="modal" data-bs-target="#productdetailmodal">{'Out of Stock'}</p>)
                    }
                    break;
                case 'DATE':
                    content = (<p className="mb-0" data-bs-toggle="modal" data-bs-target="#productdetailmodal">{moment(item.updatedOn).format('lll')}</p>)
                    break;
                case 'CUSTOM_CBD':
                    content = (
                        <label className="bl-switch mb-0">
                            <input type="checkbox" checked={item.customCbdSelected} onChange={() => changeProductState(item, !item.customCbdSelected, 'CBD')} />
                            <span className="bl-slider round"></span>
                        </label>
                    )
                    break;
                case 'CUSTOM_THC':
                    content = (
                        <label className="bl-switch mb-0">
                            <input type="checkbox" checked={item.customThcSelected} onChange={() => changeProductState(item, !item.customThcSelected, 'THC')} />
                            <span className="bl-slider round"></span>
                        </label>
                    )
                    break;
                default:
                    content = (<p className="mb-0" data-bs-toggle="modal" data-bs-target="#productdetailmodal">{''}</p>)
                    break;
            }
            return content;
        }
        return ''
    }

    const getUnitFromVariant = (item, indx) => {
        if (item) {
            const unitItems = replaceText(item);
            const unitArray = unitItems.split('|');
            const actualUnit = unitArray[indx].replace(/[^0-9/.?]/g, "");
            let num = roundToTwo(actualUnit);
            return isNaN(num) ? null : num;
        }
    }

    const replaceText = (item) => {
        if (item && item.productName && item.productGroupName) {
            return (item.productName.replace(item.productGroupName, '')).trim()
        }
        else {
            return ''
        }
    }

    function roundToTwo(num) {
        return +(Math.round(num + "e+2") + "e-2");
    }

    const gridViewContent = (i = 1) => {
        return (
            <div key={`prod-lits-row-${i}`} className="row">
                {
                    productList && productList.map((prd, j) => {
                        let productImage = prd.images && prd.images.length ? prd.images : [imgPreview];
                        let serverImage = prd.images && prd.images.length ? true : false;
                        return (
                            <div key={`product-${i}${j}`} className="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-xs-12 bl-product-card">
                                <div className="card shadow-sm mb-4">
                                    <div className="card-body p-3">
                                        <div className="form-check bl-product-checkbox">
                                            <input className="form-check-input bl-border-purple" onChange={() => selectProductToPrint(prd.productID)} type="checkbox" value={''} checked={prd.isSelected} id="flexCheckDefault" />
                                        </div>
                                        <div className={`bl-product-count-action ${prd.isSelected ? '' : 'd-none'}`}>
                                            <div className="input-group input-group-sm">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text bg-white bl-text-purple bl-border-color-purple cp" onClick={() => { prd.labelCount > 1 ? productLabelCount(prd.productID, parseInt(prd.labelCount) - 1) : productLabelCount(prd.productID, 1) }}>
                                                        <i className="fa fa-minus" aria-hidden="true"></i>
                                                    </span>
                                                </div>
                                                <input type="text" className="form-control text-center h-auto bg-white bl-text-purple bl-border-color-purple p-0" value={prd.labelCount} disabled />
                                                <div className="input-group-append">
                                                    <span className="input-group-text bg-white bl-text-purple bl-border-color-purple cp" onClick={() => productLabelCount(prd.productID, parseInt(prd.labelCount) + 1)}>
                                                        <i className="fa fa-plus" aria-hidden="true"></i>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="w-100 text-center mb-1">
                                            <CarouselComponent images={productImage} serverImage={serverImage} height="120px"></CarouselComponent>
                                        </div>
                                        <div className="cp" onClick={() => { setSelectedProduct(prd.productID); setProductCabinet(prd.cabinetName) }} data-bs-toggle="modal" data-bs-target="#productdetailmodal">
                                            <div className="textSize2 text-muted">SKU#{prd.productID ? prd.productID : ''}</div>
                                            <div className="textSize1 mb-2 bl-desc-truncate-line-1">{prd.productBrand} - <b>{prd.productGroupName}</b></div>

                                            <ul className="list-group textSize3">
                                                <li className="list-group-item d-flex justify-content-between align-items-center p-2 productCardOddRow border-0">
                                                    <p className="mb-0 text-muted">Category</p>
                                                    <p className="mb-0">{prd.productCategory}</p>
                                                </li>
                                                <li className="list-group-item d-flex justify-content-between align-items-center p-2 productCardEvenRow border-0">
                                                    <p className="mb-0 text-muted">Sub Category</p>
                                                    <p className="mb-0 bl-desc-truncate-line-1">{prd.productSubCategory}</p>
                                                </li>
                                                <li className="list-group-item d-flex justify-content-between align-items-center p-2 productCardOddRow border-0">
                                                    <p className="mb-0 text-muted">Strain</p>
                                                    <p className="mb-0">{prd.productStrain}</p>
                                                </li>
                                                <li className="list-group-item d-flex justify-content-between align-items-center p-2 productCardEvenRow border-0">
                                                    <p className="mb-0 text-muted">Inventory</p>
                                                    <p className={`mb-0 bl-desc-truncate-line-1 ${prd.inventoryCount ? 'text-success' : 'text-danger'}`}>{prd.inventoryCount ? prd.inventoryCount : 'Out of Stock'}</p>
                                                </li>
                                                <li className="list-group-item d-flex justify-content-between align-items-center p-2 productCardOddRow border-0">
                                                    <p className="mb-0 text-muted">Cabinet</p>
                                                    {
                                                        prd.cabinetName ? <p className="mb-0">{prd.cabinetName}</p> : <p className="mb-0 badge bl-chip bg-danger text-white border-0">{'Not Assigned'}</p>
                                                    }

                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
                {
                    !isLoaded && loaderContent()
                }
                {
                    productList.length === 0 && isLoaded ? (
                        <div className="col-md-3 col-sm-12 col-xs-12 bl-product-card">
                            <div className="card">
                                <div className="card-body pt-5 text-center bl-h-15rem">
                                    <img src={no_products_found} className="w-50" alt="no_products_found" />
                                </div>
                            </div>
                        </div>
                    ) : null
                }
            </div>
        )
    }

    const handleRowClick = (e) => {
        setSelectedProduct(e.data.productID)
        setProductCabinet(e.data.cabinetName)
    };

    const onSort = async (e) => {
        try {
            const sortIndex = gridSort.findIndex(g => g.val === e.sortField);
            sortProducts(null, sortIndex)
        } catch (error) {
            console.error('Error fetching sorted data:', error);
        }
    };

    const listViewContent = () => {
        return (
            <div className="card">
                <div className="card-body">
                    <DataTable value={productList} sortField={productSortBy} sortOrder={sortType === "DESC" ? 1 : -1} onSort={onSort} className="cp" onRowClick={handleRowClick} emptyMessage={isLoaded ? 'No products found' : ' '}>
                        <Column header="Product Name" field="productName" body={(e) => arrangeColumnFiled(e, 'NAME')} sortable></Column>
                        <Column header="Product ID" field="productID" body={(e) => arrangeColumnFiled(e, 'ID')} sortable></Column>
                        <Column header="Brand" field="productBrand" body={(e) => arrangeColumnFiled(e, 'BRAND')} sortable></Column>
                        <Column header="Category" field="productCategory" body={(e) => arrangeColumnFiled(e, 'CATEGORY')} sortable></Column>
                        <Column header="Sub Category" field="productSubCategory" body={(e) => arrangeColumnFiled(e, 'SUB-CATEGORY')} sortable></Column>
                        <Column header="Strain" field="productStrain" body={(e) => arrangeColumnFiled(e, 'STRAIN')} sortable></Column>
                        <Column header="Prevalence" field="productStrainPrevalence" body={(e) => arrangeColumnFiled(e, 'PREVALENCE')} sortable></Column>
                        <Column header="Size" field="packOfSize" body={(e) => arrangeColumnFiled(e, 'SIZE')}></Column>
                        <Column header="Cabinet" field="cabinetName" body={(e) => arrangeColumnFiled(e, 'CABINET')} sortable></Column>
                        <Column header="Inventory" field="inventoryCount" body={(e) => arrangeColumnFiled(e, 'INVENTORY')} sortable></Column>
                        <Column header="Updated On" field="updatedOn" body={(e) => arrangeColumnFiled(e, 'DATE')} sortable></Column>
                        <Column header="Custom THC" field="" body={(e) => arrangeColumnFiled(e, 'CUSTOM_THC')}></Column>
                        <Column header="Custom CBD" field="" body={(e) => arrangeColumnFiled(e, 'CUSTOM_CBD')}></Column>
                        {/* <Column style={{ width: '7rem' }} header="" field="" body={(e) => arrangeColumnFiled(e, 'ACTION')}></Column> */}
                    </DataTable>
                </div>
                {
                    !isLoaded && (<div className="col text-center">
                        <img className="bl-list-loader" src={listViewLoader} />
                    </div>)
                }
            </div>
        )
    }

    const loaderContent = () => {
        return (
            <section className="page-loader">
                <div className="row">
                    <div className="col loader"></div>
                    <div className="col loader"></div>
                    <div className="col loader"></div>
                    <div className="col loader"></div>
                    <div className="col loader"></div>
                </div>
            </section>
        )
    }

    const printAlert = () => {
        return (
            <div className="modal fade" id="printalertmodal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h6 className="modal-title" id="exampleModalLongTitle">Select a print layout to proceed</h6>
                            <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close" >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div>
                                <div className="form-check form-check-inline">Select option :</div>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="radio" onChange={() => setPrintType('CARD')} name="inlineRadioOptions" id="inlineRadio1" value="option1" checked={printType === 'CARD'} />
                                    <label className="form-check-label" htmlFor="inlineRadio1">Card Type</label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="radio" onChange={() => setPrintType('LIST')} name="inlineRadioOptions" id="inlineRadio2" value="option2" checked={printType === 'LIST'} />
                                    <label className="form-check-label" htmlFor="inlineRadio2">List Type</label>
                                </div>
                                {
                                    printType === 'LIST' && isMultiBrandForListView ? <div className="alert alert-warning mb-0 mt-2" role="alert">
                                        <strong>Warning - Multiple brands or size selected</strong> <br />You can print this card with products from a single brand and one size.
                                    </div> : null
                                }
                            </div>
                        </div>
                        <div className="modal-footer p-1">
                            <button type="button" className="btn btn-secondary btn-sm" data-bs-dismiss="modal">Cancel</button>
                            <button type="button" className="btn btn-primary btn-sm" disabled={printType === 'LIST' && isMultiBrandForListView} onClick={printLabel}>Print Label</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const cabinetProductAssignAlert = () => {
        return (
            <div className="modal fade" id="cabinetproductmodal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" role="dialog" aria-labelledby="cabinetproductassignTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h6 className="modal-title" id="cabinetproductassignTitle">{'Manage Product Cabinet'}</h6>
                            <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close" >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <form id="cabinetproductassignunassign" className="needs-validation">
                            <div className="modal-body">
                                <p>Number of selected products : <b>{selectedProductList.length}</b></p>
                                <div className="form-group">
                                    <select className="custom-select custom-select-sm" value={selectedCabinet} onChange={(e) => setSelectedCabinet(e.target.value)} required>
                                        <option value="">Select Cabinet</option>
                                        {
                                            cabinets && cabinets.map((c, i) => {
                                                return (
                                                    <option key={`cabinet-${i}`} value={c.cabinetId}>{c.cabinetName}</option>
                                                )
                                            })
                                        }
                                    </select>
                                    <div className="invalid-tooltip bl-top-75">Please select anyone cabinet from the dropdown</div>
                                </div>
                            </div>
                            <div className="modal-footer p-1">
                                <button type="button" className="btn btn-secondary btn-sm" data-bs-dismiss="modal">Cancel</button>
                                <button type="submit" className="btn btn-primary btn-sm" onClick={modifyProductCabinet}>Save</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }

    const reloadProduct = () => {
        setPage(1);
        setProductList([]);
        setFetchedProductCount(0)
        setTimeout(() => {
            getProductList({ filterItem: null })
        }, 200)
    }

    return (
        <div id="content" ref={contentRef}>
            <div className="container-fluid pt-3">
                <div className="row">
                    <div className="col-12">
                        <section className="mb-4">
                            <div className="card bl-content-overview">
                                <div className="card-body d-flex justify-content-between">
                                    <div className="align-self-center mr-16">
                                        <h5>{labels.WELCOME}</h5>
                                        <p className="mb-0">{labels.WELCOME_DESC}</p>
                                    </div>
                                    <div className="align-self-center mob-hide">
                                        <img className="bl-h-2" src={bannerIcon} alt="overview-Img" />
                                    </div>
                                </div>
                                <div className="card-footer bg-transparent p-0">
                                    <button className="btn btn-link text-small">{labels.TIPS}</button>
                                </div>
                            </div>
                        </section>
                        {/* <ListLabelPrint ref={componentRefList} productList={printedProducts}></ListLabelPrint> */}
                        <section className="mb-4">
                            <section id="sticktitle" className="mb-3 d-flex justify-content-between align-items-center sticky-container">
                                <h6 className="mb-0">Showing {productList.length} of {totalProductCount} Products</h6>
                                <div className="d-flex">
                                    {/* <div className="custom-control custom-switch mr-2 d-flex align-items-center textSize2">
                                        <input type="checkbox" className="custom-control-input" id="customSwitch1" />
                                        <label className="custom-control-label" htmlFor="customSwitch1">Show In-Stock Items only</label>
                                    </div> */}
                                    {/* {showAssignedButton && (<button className="btn btn-secondary btn-sm mr-2" onClick={(e) => { getSelectedProducts(e) }} data-bs-toggle="modal" data-bs-target="#cabinetproductmodal">Assign Cabinet</button>)} */}
                                    {(showUnAssignedButton || showAssignedButton) && (<button className="btn btn-secondary btn-sm mr-2" onClick={(e) => { getSelectedProducts(e) }} data-bs-toggle="modal" data-bs-target="#cabinetproductmodal">Manage Cabinet</button>)}
                                    <button className="btn btn-secondary btn-sm mr-2" onClick={() => setisMultiBrandForListView(false)} data-bs-toggle="modal" data-bs-target="#printalertmodal"><i className="fa fa-print" aria-hidden="true"></i> Print Card</button>
                                    {
                                        viewType === 'GRID' ? (<>
                                            <button id="dropdownSortButton" className="btn btn-outline-primary btn-sm dropdown-toggle mr-2" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                {productSortByName}{
                                                    sortType === "DESC" ? <i className="fa fa-sort-amount-desc ml-1" aria-hidden="true"></i> :
                                                        <i className="fa fa-sort-amount-desc bl-rotate-riverse-y ml-1" aria-hidden="true"></i>
                                                }
                                            </button>
                                            <div className="dropdown-menu dropdown-menu-bottom shadow" aria-labelledby="dropdownSortButton">
                                                {
                                                    gridSort && gridSort.map((s, i) => {
                                                        return (
                                                            <button key={`dropdownitem-${i}`} className={`btn dropdown-item ${s.isSorted ? 'text-primary' : ''}`} onClick={(e) => sortProducts(e, i)}>{s.name}

                                                                {
                                                                    s.isSorted ? sortType === "DESC" ? <i className="fa fa-sort-amount-desc ml-2" aria-hidden="true"></i> :
                                                                        <i className="fa fa-sort-amount-desc bl-rotate-riverse-y ml-2" aria-hidden="true"></i> :
                                                                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" className="p-icon p-sortable-column-icon ml-2 bl-sort-icon" aria-hidden="true" data-pc-section="sorticon"><g clipPath="url(#pr_icon_clip_1)"><path d="M5.64515 3.61291C5.47353 3.61291 5.30192 3.54968 5.16644 3.4142L3.38708 1.63484L1.60773 3.4142C1.34579 3.67613 0.912244 3.67613 0.650309 3.4142C0.388374 3.15226 0.388374 2.71871 0.650309 2.45678L2.90837 0.198712C3.17031 -0.0632236 3.60386 -0.0632236 3.86579 0.198712L6.12386 2.45678C6.38579 2.71871 6.38579 3.15226 6.12386 3.4142C5.98837 3.54968 5.81676 3.61291 5.64515 3.61291Z" fill="currentColor"></path><path d="M3.38714 14C3.01681 14 2.70972 13.6929 2.70972 13.3226V0.677419C2.70972 0.307097 3.01681 0 3.38714 0C3.75746 0 4.06456 0.307097 4.06456 0.677419V13.3226C4.06456 13.6929 3.75746 14 3.38714 14Z" fill="currentColor"></path><path d="M10.6129 14C10.4413 14 10.2697 13.9368 10.1342 13.8013L7.87611 11.5432C7.61418 11.2813 7.61418 10.8477 7.87611 10.5858C8.13805 10.3239 8.5716 10.3239 8.83353 10.5858L10.6129 12.3652L12.3922 10.5858C12.6542 10.3239 13.0877 10.3239 13.3497 10.5858C13.6116 10.8477 13.6116 11.2813 13.3497 11.5432L11.0916 13.8013C10.9561 13.9368 10.7845 14 10.6129 14Z" fill="currentColor"></path><path d="M10.6129 14C10.2426 14 9.93552 13.6929 9.93552 13.3226V0.677419C9.93552 0.307097 10.2426 0 10.6129 0C10.9833 0 11.2904 0.307097 11.2904 0.677419V13.3226C11.2904 13.6929 10.9832 14 10.6129 14Z" fill="currentColor"></path></g><defs><clipPath id="pr_icon_clip_1"><rect width="14" height="14" fill="white"></rect></clipPath></defs></svg>
                                                                }
                                                            </button>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </>
                                        ) : null
                                    }
                                    <div className="btn-group" role="group" aria-label="Basic example">
                                        <button className={`btn btn-sm ${viewType === 'GRID' ? 'btn-primary' : 'btn-outline-primary'}`} onClick={() => changeViewType('GRID')}><i className="fa fa-th-large" aria-hidden="true"></i></button>
                                        <button className={`btn btn-sm ${viewType === 'LIST' ? 'btn-primary' : 'btn-outline-primary'}`} onClick={() => changeViewType('LIST')}><i className="fa fa-list" aria-hidden="true"></i></button>
                                    </div>
                                </div>
                            </section>
                            {
                                // filterList.length || unassignedProduct ? (
                                <section className="mb-4">
                                    <div className="card-filter p-3 border-0 rounded">
                                        <div className="col-xl-12 col-md-12">
                                            <form>
                                                <div className="form-row blc_button align-items-end">
                                                    <div className="form-group mr-2 mb-0">
                                                        <label htmlFor="inputState">Category</label>
                                                        <select id="inputState" className="custom-select custom-select-sm" onChange={(e) => getSubCategoryAndStrianByCabinetCategory(e)} value={selectedCategory}>
                                                            <option value={''}>All</option>
                                                            {
                                                                categoryList && categoryList.map((cat, i) => {
                                                                    return (
                                                                        <option key={`cab-cat-${i}`} value={cat.productCategory}>{cat.productCategory}</option>
                                                                    )
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                    <div className="form-group mr-2 mb-0">
                                                        <label htmlFor="inputState">Sub Category</label>
                                                        <select id="inputState" className="custom-select custom-select-sm" onChange={(e) => { e.preventDefault(); setSelectedSubCategory(e.target.value) }} value={selectedSubCategory}>
                                                            <option value={''}>All</option>
                                                            {
                                                                subcategoryList && subcategoryList.map((subcat, i) => {
                                                                    return (
                                                                        <option key={`cab-cat-sub-${i}`} value={subcat.productSubCategory}>{subcat.productSubCategory}</option>
                                                                    )
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                    <div className="form-group mr-2 mb-0">
                                                        <label htmlFor="inputState">Strain</label>
                                                        <select id="inputState" className="custom-select custom-select-sm" onChange={(e) => { e.preventDefault(); setSelectedStrain(e.target.value) }} value={selectedStrain}>
                                                            <option value={''}>All</option>
                                                            {
                                                                strainList && strainList.map((strain, i) => {
                                                                    return (
                                                                        <option key={`cab-cat-strain-${i}`} value={strain.productStrain}>{strain.productStrain}</option>
                                                                    )
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                    <div className="form-group mr-2 mb-0">
                                                        <label htmlFor="inputState">Stock</label>
                                                        <select id="inputState" className="custom-select custom-select-sm" onChange={(e) => { e.preventDefault(); setSelectedStock(e.target.value) }} value={selectedStock}>
                                                            <option value={''}>All</option>
                                                            <option value={'inStock'}>In Stock</option>
                                                            <option value={'outOfStock'}>Out of Stock</option>
                                                        </select>
                                                    </div>
                                                    <button className="btn btn-filter btn-sm" onClick={(e) => applyFilters(e)} disabled={!isLoaded}>Apply Filter</button>
                                                    <button className="btn btn-link btn-sm bl-filter-clear" onClick={(e) => clearFilters(e)} disabled={!isLoaded}>Clear All</button>

                                                </div>
                                            </form>

                                        </div>
                                    </div>
                                </section>
                                // ) : null
                            }
                            {/* <ListLabelPrint ref={componentRefList} productList={printedProducts}></ListLabelPrint> */}

                            {
                                isErrorOccured ? <UnknownErrorComponent /> : viewType === 'GRID' ? gridViewContent() : listViewContent()
                            }
                        </section>
                        <ProductDetailModal productId={selectedProduct} cabinet={productCabinet} reloadProduct={reloadProduct}></ProductDetailModal>
                        <div className="d-none">
                            <ListLabelPrint ref={componentRefList} productList={printedProducts}></ListLabelPrint>
                            <PrintProductLabel ref={componentRef} productInfo={printedProducts} />
                        </div>
                        {printAlert()}
                        {cabinetProductAssignAlert()}
                    </div>
                </div>
            </div>
        </div>
    )
}