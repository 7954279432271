import React, { useEffect, useState } from "react";
import JL_Logo from "./../../assets/logo/jl-logo.png"
import letter1Img from "./../../assets/img/Joyleaf_Species_Sativa.jpg"
import { effectIcons, speciesIcons } from "../../assets/iconFile";


export const PrintProductLabel = React.forwardRef(({ productInfo }, ref) => {

    const [products, setProducts] = useState([])

    useEffect(() => {
        setProducts(productInfo)
    }, [productInfo])

    const topCategoryLabelTemplateContent = (item) => {
        if (item.productCategory && item.productCategory.toLowerCase().trim() === "flower") {
            return <h5 className="text-uppercase bl-ozik-medium-21pt mb-0">{item.productType}</h5>
        }
        else if (item.productCategory && ["vape", "edible"].includes(item.productCategory.toLowerCase().trim())) {
            return (
                <div>
                    <p className="text-uppercase bl-ozik-medium-21pt text-left mb-0 bl-lineheight-23px">{item.productSubCategory}</p>
                    <p className="text-uppercase bl-ozik-medium-21pt text-left mb-0 bl-lineheight-23px">{item.productQualityLine ? item.productQualityLine : ''}</p>
                </div>
            )
        }
        else if (item.productCategory && item.productCategory.toLowerCase().trim() === "concentrate") {
            return <h5 className="text-uppercase bl-ozik-medium-21pt mb-0">{item.productSubCategory}</h5>
        }
        else {
            return <h5 className="text-uppercase bl-ozik-medium-21pt mb-0">{item.productCategory}</h5>
        }
    }

    const getLabelSpeciesImage = (item) => {
        let speciesKeys = Object.keys(speciesIcons);
        if (item && item.productStrainPrevalence) {
            if (item.productStrainPrevalence.toUpperCase().search('DOMINANT') >= 0) {
                if (item.productStrainPrevalence.toLowerCase().search('sativa') >= 0) {
                    let indx = speciesKeys.findIndex(e => e == 'hybridsativa');
                    if (indx >= 0) {
                        return speciesIcons[speciesKeys[indx]]
                    }
                }
                else if (item.productStrainPrevalence.toLowerCase().search('indica') >= 0) {
                    let indx = speciesKeys.findIndex(e => e == 'hybridindica');
                    if (indx >= 0) {
                        return speciesIcons[speciesKeys[indx]]
                    }
                }
            }
            let productStrain = item.productStrainPrevalence.split(" ").join("").toLowerCase()
            let indx = speciesKeys.findIndex(e => e == productStrain);
            if (indx >= 0) {
                return speciesIcons[speciesKeys[indx]]
            }

        }
        return letter1Img
    }

    const getUOMShort = (unit) => {
        if (unit && unit.toLowerCase() === "milligram") {
            return 'MG'
        }
        else if (unit && unit.toLowerCase() === "each") {
            return 'EA'
        }
        else if (unit) {
            return unit.charAt(0)
        }
        else return ''
    }

    const getProductType = (item) => {
        if (item.productType === '|') {
            return item.productType.substring(1).trim()
        }
        else return item.productType.trim()
    }

    function roundToTwo(num) {
        return +(Math.round(num + "e+2") + "e-2");
    }

    const getTotalPackSize = (item) => {
        if (item) {
            let packNum = item.productType.replace(/^\D+/g, '');
            let totalPack = getUnitFromVariant(item, 0) * parseFloat(packNum)
            return roundToTwo(totalPack)
        }
        else return ''
    }

    const displayTHCValue = (item) => {
        if(item){
            if(item.customThcSelected){
                return item.customThc ? (item.customThc) : '0'
            }
            else {
                return item.thc ? Math.round(item.thc) : '0'
            }
        }
        return '0'
    }

    const middlePriceLabelTemplateContent = (item) => {
        if (item.productCategory && (item.productCategory.toLowerCase().trim() === "pre-roll" || item.productCategory.toLowerCase().trim() === "pre roll") && item.productSubCategory.toLowerCase().trim() === "single") {
            return (
                <div className="d-flex justify-content-between align-items-center bl-border-bottom-purple border-2 bl-height-90px">
                    <div className="col bl-border-right-purple border-2">
                        <h4 className="text-uppercase bl-barlow-bold-35pt mb-0 text-nowrap">${item.productPrice}</h4>
                    </div>
                    <div className="col bl-border-right-purple border-2">
                        <p className="bl-barlow-bold-14pt mb-0 text-nowrap"><span className="bl-font-larger">{item.productSubCategory}</span></p>
                        <p className="bl-product-id bl-lineheight-23px fw-bold mb-0 text-nowrap">{getUnitFromVariant(item, 0)}{getUOMShort(item.uom)}</p>
                    </div>
                    <div className="col pt-3">
                        <h4 className="text-uppercase bl-barlow-bold-35pt mb-0 text-nowrap">{displayTHCValue(item)}%</h4>
                        <p className="bl-product-thc mb-1 mt-n2">THC</p>
                    </div>
                </div>
            )
        }
        if (item.productCategory && (item.productCategory.toLowerCase().trim() === "pre-roll" || item.productCategory.toLowerCase().trim() === "pre roll") && item.productSubCategory.toLowerCase().trim() !== "single") {
            return (
                <div className="d-flex justify-content-between align-items-center bl-border-bottom-purple border-2 bl-height-90px">
                    <div className="col">
                        <h4 className="text-uppercase bl-barlow-bold-35pt mb-0 text-nowrap">${item.productPrice}</h4>
                    </div>
                    <div className="col bl-border-right-purple bl-border-left-purple border-2">
                        <p className="bl-barlow-bold-14pt mb-0 bl-lineheight-23px text-nowrap"><span className="bl-font-larger">{item.productSubCategory}</span></p>
                        <p className="bl-product-id mb-0 bl-lineheight-23px fw-bold text-nowrap">{getUnitFromVariant(item, 0)}{getUOMShort(item.uom)} Total</p>
                        <p className="bl-product-id mb-0 bl-lineheight-23px fw-bold text-nowrap">{getUnitFromVariant(item, 1)}{getUOMShort(item.uom)} Each</p>
                    </div>
                    <div className="col pt-3">
                        <h4 className="text-uppercase bl-barlow-bold-35pt mb-0 text-nowrap">{displayTHCValue(item)}%</h4>
                        <p className="bl-product-thc mb-1 mt-n2">THC</p>
                    </div>
                </div>
            )
        }
        else if (item.productCategory && ["edible (disabled)", "edible"].includes(item.productCategory.toLowerCase().trim())) {
            return (
                <div className="d-flex justify-content-between align-items-center bl-border-bottom-purple border-2 bl-height-90px">
                    <div className="col">
                        <h4 className="bl-barlow-bold-35pt mb-0 text-nowrap">${item.productPrice}</h4>
                    </div>
                    <div className="col bl-border-left-purple border-2">
                        <p className="bl-barlow-bold-14pt mb-0 bl-lineheight-23px text-nowrap"><span className="bl-font-larger">{getProductType(item).substring(1).trim()}</span></p>
                        <p className="bl-product-id mb-0 bl-lineheight-23px fw-bold text-nowrap">{getUnitFromVariant(item, 0)}{getUOMShort(item.uom)} Each</p>
                        <p className="bl-product-id mb-0 bl-lineheight-23px fw-bold text-nowrap">{getTotalPackSize(item)}{getUOMShort(item.uom)} Total</p>
                    </div>
                </div>
            )
        }
        else if (item.productCategory && ["vape", "concentrate"].includes(item.productCategory.toLowerCase().trim())) {
            return (
                <div className="d-flex justify-content-between align-items-center bl-border-bottom-purple border-2 bl-height-90px">
                    <div className="col">
                        <h4 className="text-uppercase bl-barlow-bold-35pt mb-0 text-nowrap">${item.productPrice}</h4>
                    </div>
                    <div className="col bl-border-right-purple bl-border-left-purple border-2">
                        <h4 className="bl-barlow-bold-35pt mb-0 text-nowrap">{getUnitFromVariant(item, 0)}{getUOMShort(item.uom)}</h4>
                    </div>
                    <div className="col pt-3">
                        <h4 className="text-uppercase bl-barlow-bold-35pt mb-0 text-nowrap">{displayTHCValue(item)}%</h4>
                        <p className="bl-product-thc mb-1 mt-n2">THC</p>
                    </div>
                </div>
            )
        }
        else {
            return (
                <div className="d-flex justify-content-between align-items-center bl-border-bottom-purple border-2 bl-height-90px">
                    <div className="col">
                        <h4 className="text-uppercase bl-barlow-bold-35pt mb-0 text-nowrap">${item.productPrice}</h4>
                    </div>
                    <div className="col bl-border-right-purple bl-border-left-purple border-2">
                        <h4 className="bl-barlow-bold-35pt mb-0 text-uppercase text-nowrap">{replaceText(item)}</h4>
                    </div>
                    <div className="col pt-3">
                        <h4 className="text-uppercase bl-barlow-bold-35pt mb-0 text-nowrap">{displayTHCValue(item)}%</h4>
                        <p className="bl-product-thc mb-1 mt-n2">THC</p>
                    </div>
                </div>
            )
        }
    }

    const getUnitFromVariant = (item, indx) => {
        if (item) {
            const unitItems = replaceText(item);
            const unitArray = unitItems.split('|');
            if(unitArray && unitArray.length >= indx+1){
                const actualUnit = unitArray[indx].replace(/[^0-9/.?]/g, "");
                return roundToTwo(actualUnit);
            }
            else if(indx !== 0){
                const pack = item.productSubCategory.split(' ')[0];
                if(!isNaN(pack)){
                    const firstUnit = unitArray[0].replace(/[^0-9/.?]/g, "");
                    return firstUnit/parseInt(pack);
                }
            }
            return ''
        }
    }

    const replaceText = (item) => {
        if (item && item.productName && item.productGroupName) {
            return (item.productName.replace(item.productGroupName, '')).trim()
        }
        else {
            return ''
        }
    }

    const getLabelEffectImage = (item) => {
        let effectKeys = Object.keys(effectIcons);
        if (item && item.productEffectName) {
            let indx = effectKeys.findIndex(e => e == item.productEffectName.toLowerCase());
            if (indx >= 0) {
                return effectIcons[effectKeys[indx]]
            }
        }
        return ''
    }

    const getCardProductName = (item) => {
        if (item) {
            if (item.productCategory && ["edible", "pre-roll", "pre roll"].includes(item.productCategory.toLowerCase().trim())) {
                return item.productGroupName
            }
            else return item.productStrain ? item.productStrain : item.productGroupName;
        }
        else return ''
    }

    const splitTheWord = (item) => {
        if (item) {
            const words = item.productStrainPrevalence.split(' ');
            return (
                <div className="pr-1">
                    {
                        words && words.map((w, i) => {
                            return <p key={`split-prevelance-${i}`} className="text-uppercase bl-barlow-bold-14pt mb-0 bl-lineheight-18px">{w}</p>
                        })
                    }
                </div>
            )
        }
        else return ''
    }

    return (
        <div className="row" ref={ref}>
            {
                products && products.map((product, i) => {
                    return (
                        <div key={`print-product-label-${i}`} className="border bl-print-card bl-text-purple text-center">
                            <div className="d-flex justify-content-between bl-border-bottom-purple border-2 bl-height-62px align-items-center">
                                {
                                    topCategoryLabelTemplateContent(product)
                                }
                                <div className="d-flex align-items-center">
                                    {
                                        product.productStrainPrevalence && product.productStrainPrevalence.toLowerCase() !== 'n/s' ? (
                                            <>
                                                {splitTheWord(product)}
                                                <img src={getLabelSpeciesImage(product)} alt="" className="bl-h-1-9rem" />
                                            </>
                                        ) : null
                                    }

                                </div>
                            </div>
                            <div className="text-center bl-border-bottom-purple border-2 d-flex justify-content-center align-items-center bl-height-120px">
                                <div className="w-100">
                                    <h1 className="text-uppercase bl-ozik-bold-35pt bl-lineheight-36px bl-desc-truncate-line-2">{getCardProductName(product)}</h1>
                                    <h5 className="text-uppercase bl-ozik-medium-18pt mb-0">{product.productBrand}</h5>
                                </div>

                            </div>

                            {
                                middlePriceLabelTemplateContent(product)
                            }
                            <div className="d-flex justify-content-center align-items-center bl-height-35px">
                                {
                                    product.productEffectName ? (
                                        <img src={getLabelEffectImage(product)} alt="" className="bl-h-1-4rem" />
                                    ) : null
                                }
                            </div>
                            <div className="d-flex justify-content-center align-items-center bl-height-50px">
                                <p className="bl-barlow-medium-15pt bl-desc-truncate-line-2 mb-0 bl-lineheight-23px">{product.shortDescription}</p>
                            </div>
                            <div className="d-flex justify-content-between align-items-baseline mt-3">
                                <h5 className="text-uppercase mb-0 bl-barlow-medium-15pt">{product.productID}</h5>
                                <img src={JL_Logo} alt="JL-logo" className="img-fluid bl-jf-logo" />
                            </div>
                            <div className="bl-border-purple border-3 mb-2 bl-border-radius-5px"></div>
                            {
                                product.cabinetName ? (
                                    <div className="text-center mt-4">
                                        <p className="mb-0"><i>({product.cabinetName})</i></p>
                                    </div>
                                ) : null
                            }

                            {
                                (i + 1) % 6 === 0 ? <div className="pagebreak"></div> : null
                            }
                        </div>
                    )
                })
            }
        </div>
    )
})